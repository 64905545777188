* {
	margin: 0;
	padding: 0;
}

#loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.lotties {
	display: flex;
	grid-template-columns: auto;
	justify-content: center;
	align-items: center;
  }
  
  @media only screen and (min-width: 800px) {
	.lotties {
	  display: grid;
	  grid-template-columns: auto auto;
	}
  }

