/* CSS Document */

/*   01 - Loading Transition  */
/*   02 - Global style  */
/*   03 - Prefix  */
/*   04 - Section Title */
/*   05 - Theme Button */
/*   06 - Pagination  */
/*   07 - Theme Menu  */
/*   08 - Theme Hero Banner/One  */
/*   09 - Fancy Feature One  */
/*   10 - TFancy Feature Two  */
/*   11 - Useable Tools  */
/*   12 - Fancy Feature Three  */
/*   13 - Fancy Short Banner One  */
/*   14 - Faq Classic   */
/*   15 - Footer One  */
/*   16 - Fancy Hero One  */
/*   17 - Fancy Text block One  */
/*   18 - Counter Info Standard  */
/*   19 - Fancy Text block Two   */
/*   20 - Fancy Text block Three */
/*   21 - Team Section One */
/*   22 - Feature Blog One */
/*   23 - Fancy Text block Four  */
/*   24 - Fancy Text block Five  */
/*   25 - Feature Blog Two  */
/*   26 - Contact Us Light */
/*   27 - Theme Hero Banner/Two  */
/*   28 - Fancy Feature Two   */
/*   29 - Fancy Text block Six   */
/*   30 - Counter With Icon One   */
/*   31 - Fancy Text block Seven   */
/*   32 - Fancy Text block Eight   */
/*   33 - Useable Tools Two   */
/*   34 - Client Feedback Slider One   */
/*   35 - Fancy Short Banner Three   */
/*   36 - Footer Style Two   */
/*   37 - Fancy Hero Two   */
/*   38 - Fancy Text block Nine  */
/*   39 - Fancy Text block Ten   */
/*   40 - Fancy Feature Five   */
/*   41 - Team Section Two   */
/*   42 - Fancy Hero Three   */
/*   43 - Fancy Text block Eleven   */
/*   44 - Fancy Feature Six   */
/*   45 - Fancy Text block Twelve   */
/*   46 - Fancy Feature Seven   */
/*   47 - Fancy Text Block Thirteen   */
/*   48 - Fancy Text Block Fourteen   */
/*   49 - Pricing Section One   */
/*   50 - Fancy Text Block Fifteen   */
/*   51 - Fancy Hero Four   */
/*   52 - Pricing Section Two   */
/*   53 - FAQ Section   */
/*   54 - Contact Style Two   */
/*   55 - Faqs   */
/*   56 - Login/Sign Up   */
/*   57 - Blog Pages  */
/*   58 - Theme Hero Banner/Three  */
/*   59 - Fancy Feature Eight   */
/*   60 - Fancy Text block Sixteen   */
/*   61 - Fancy Feature Eight   */
/*   62 - Client Feedback Slider Two   */
/*   63 - Useable Tools / Three  */
/*   64 - Fancy Short Banner Four   */
/*   65 - Footer Style Three   */
/*   66 - Fancy Hero Five   */
/*   67 - Fancy Text block Seventeen   */
/*   68 - Fancy Text block Eighteen   */
/*   69 - Team Section Three  */
/*   70 - Map Area One    */
/*   71 - Documentation  */
/*   72 - Theme Hero Banner/Four   */
/*   73 - Fancy Feature Eight   */
/*   74 - Fancy Text block Nineteen   */
/*   75 - Fancy Feature Nine   */
/*   76 - Pricing Section Three   */
/*   77 - Client Feedback Slider Three   */
/*   78 - Faq Section Three   */
/*   79 - Fancy Short Banner Five   */

/*========================================================================================

*************************** Start Styling Your theme from here ***************************

==========================================================================================*/
/*** 

====================================================================
  Loading Transition
====================================================================

 ***/
.ctn-preloader {
  align-items: center;
  -webkit-align-items: center;
  display: flex;
  display: -ms-flexbox;
  height: 100%;
  justify-content: center;
  -webkit-justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999999;
  background: #fff;
}

.ctn-preloader .animation-preloader {
  position: absolute;
  z-index: 100;
}

/* Spinner cargando */
.ctn-preloader .animation-preloader .spinner {
  animation: spinner 1.5s infinite linear;
  border-radius: 50%;
  border: 3px solid rgba(101, 92, 151, 0.06%);
  border-top-color: #655c97;
  border-bottom-color: #655c97;
  height: 170px;
  margin: 0 auto 45px auto;
  width: 170px;
}

/* Texto cargando */
.ctn-preloader .animation-preloader .txt-loading {
  text-align: center;
  user-select: none;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:before {
  animation: letters-loading 4s infinite;
  color: #655c97;
  content: attr(data-text-preloader);
  left: 0;
  opacity: 0;
  top: 0;
  line-height: 70px;
  position: absolute;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  letter-spacing: 15px;
  display: inline-block;
  color: rgba(101, 92, 151, 0.14%);
  position: relative;
  font-size: 70px;
  line-height: 70px;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(2):before {
  animation-delay: 0.2s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(3):before {
  animation-delay: 0.4s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(4):before {
  animation-delay: 0.6s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(5):before {
  animation-delay: 0.8s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(6):before {
  animation-delay: 1s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(7):before {
  animation-delay: 1.2s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(8):before {
  animation-delay: 1.4s;
}

.ctn-preloader .loader-section {
  background-color: #ffffff;
  height: 100%;
  position: fixed;
  top: 0;
  width: calc(50% + 1px);
}

.loaded .animation-preloader {
  opacity: 0;
  transition: 0.3s ease-out;
}

/* Animación del preloader */
@keyframes spinner {
  to {
    transform: rotateZ(360deg);
  }
}

@keyframes letters-loading {

  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

@media screen and (max-width: 767px) {
  .ctn-preloader .animation-preloader .spinner {
    height: 8em;
    width: 8em;
  }
}

@media screen and (max-width: 500px) {
  .ctn-preloader .animation-preloader .spinner {
    height: 7em;
    width: 7em;
  }

  .ctn-preloader .animation-preloader .txt-loading .letters-loading {
    font-size: 40px;
    letter-spacing: 10px;
  }
}

/*==================== Click Top ====================*/
.scroll-top {
  width: 35px;
  height: 35px;
  line-height: 35px;
  position: fixed;
  bottom: 20px;
  right: 5px;
  z-index: 99;
  text-align: center;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  border-radius: 50%;
  background: #000;
  transition: all 0.3s ease-in-out;

  img {
    margin: auto;
  }
}

.scroll-top:after {
  position: absolute;
  z-index: -1;
  content: "";
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 1;
  background: radial-gradient(ellipse at center,
      rgba(0, 0, 0, 0.25) 0%,
      rgba(0, 0, 0, 0) 80%);
}

/*------------- Global Prefix -------------------*/
/* Webkit Browsers (Chrome, Safari) */
// ::-webkit-scrollbar {
//   width: 5px;
//   /* Set the width of the scrollbar */

// }

// ::-webkit-scrollbar-track {
//   background: #fff;
//   /* Set the background of the track */
// }

// ::-webkit-scrollbar-thumb {
//   background: var(--blue-dark);
//   /* Set the thumb color */
//   border-radius: 5px;
//   /* Set the border radius for rounded corners */
// }
/* Hide scrollbars for WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 0px;
}

/* Hide scrollbars for Firefox */
* {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
}

:root {
  --text-color: #73737b;
  --text-light: #888888;
  --heading: #101621;
  --blue-dark: #6f55ff;
  --red-light: #fd6a5e;
  --yellow-deep: #ffb840;
  --blue-light: #3bb0d7;
  --purple-blue: #655c97;
  --vin-red: #973c56;
  scroll-behavior: smooth;
}

::selection {
  background-color: var(--blue-dark);
  color: #fff;
}

body {
  -ms-overflow-style: none;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 18px;
  position: relative;
  line-height: 1.7em;
  color: var(--text-color);
}

/*______________________ Global style ___________________*/
.main-page-wrapper {
  overflow: hidden;
  padding-top: 150px;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  color: var(--heading);
  font-weight: normal;
}

.h1,
h1 {
  font-size: 80px;
  line-height: 1.1em;
}

.h2,
h2 {
  font-size: 52px;
  line-height: 1.1em;
}

.h3,
h3 {
  font-size: 36px;
}

.h4,
h4 {
  font-size: 22px;
}

.h5,
h5 {
  font-size: 20px;
}

.h6,
h6 {
  font-size: 15px;
}

.font-rubik {
  font-family: "Rubik", sans-serif !important;
}

.font-gilroy-bold {
  font-family: "gilroy-bold" !important;
}

.font-slab {
  font-family: "Roboto Slab", serif !important;
}

.font-gordita {
  font-family: "gordita" !important;
}

.font-recoleta {
  font-family: "Recoleta" !important;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #acaeb3;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #acaeb3;
}

::placeholder {
  color: #acaeb3;
}

.cs-screen {
  position: absolute;
}

.illustration-holder {
  position: relative;
}

.shapes {
  position: absolute;
  z-index: -1;
}

.dark-style {
  background: #0f1123;
  color: #afafaf;
}

.dark-style .h1,
.dark-style h1,
.dark-style .h2,
.dark-style h2,
.dark-style .h3,
.dark-style h3,
.dark-style .h4,
.dark-style h4,
.dark-style .h5,
.dark-style h5,
.dark-style .h6,
.dark-style h6 {
  color: #fff;
}

.gr-bg-one {
  background: linear-gradient(90.6deg, #f548a0 2.93%, #f57c35 99.47%);
}

.hover-reverse-gr-bg-one {
  position: relative;
  z-index: 1;
}

.hover-reverse-gr-bg-one:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background: linear-gradient(270.01deg, #f548a0 0.01%, #f57c35 100%);
  border-radius: 6px;
  transition: all 0.6s ease-in-out;
  opacity: 0;
  z-index: -1;
}

.hover-reverse-gr-bg-one:hover:before {
  opacity: 1;
}

.top-border {
  border-top: 1px solid #efefef;
}

.bottom-border {
  border-bottom: 1px solid #efefef;
}

/*-------------- Prefix Classes ----------------*/
.tran3s {
  transition: all 0.3s ease-in-out;
}

.tran4s {
  transition: all 0.4s ease-in-out;
}

.tran5s {
  transition: all 0.5s ease-in-out;
}

.tran6s {
  transition: all 0.6s ease-in-out;
}

/*----------------- Partner Section One -------------------*/
.partner-section-one {
  position: relative;
  z-index: 1;
}

.partner-section-one .img-box a {
  display: block;
  height: 100%;
}

.partner-section-one .img-box img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
}

.partner-section-one .img-box {
  background: #fff;
  border-radius: 50%;
  box-shadow: 15px 30px 50px rgba(26, 34, 72, 0.08);
  width: 175px;
  height: 175px;
  margin: 0 auto 35px;
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 575px) {
    width: 150px;
    height: 150px;
  }
}

.partner-section-one.bg-transparent .img-box {
  box-shadow: 15px 30px 50px rgba(23, 32, 90, 0.06);
}

.partner-section-one .img-box:hover {
  transform: scale(1.1);
}

.partner-section-one .img-box.bx-b {
  width: 121px;
  height: 121px;
  margin-top: 45px;
}

.partner-section-one .img-box.bx-d {
  width: 151px;
  height: 151px;
  margin-top: 25px;
}

.partner-section-one .img-box.bx-f {
  width: 135px;
  height: 135px;
  margin-top: 20px;
}

.partner-section-one .img-box.bx-g {
  width: 197px;
  height: 197px;

  @media screen and (max-width: 575px) {
    width: 150px;
    height: 150px;
  }
}

.partner-section-one .img-box.bx-h {
  width: 138px;
  height: 138px;
  margin-top: 20px;
}

.partner-section-one .shape-one {
  top: 9%;
  right: 14%;
  animation: rotated 12s infinite linear;
}

.partner-section-one .shape-two {
  top: 44%;
  right: 9%;
  animation: rotatedTwo 12s infinite linear;
}

.partner-section-one .shape-three {
  bottom: 19%;
  right: 12%;
  animation: jumpTwo 3s infinite linear;
}

.partner-section-one .shape-four {
  bottom: 0;
  right: 36%;
  animation: rotated 12s infinite linear;
}

.partner-section-one .shape-five {
  bottom: 0;
  left: 36%;
  animation: rotatedTwo 12s infinite linear;
}

.partner-section-one .shape-six {
  bottom: 17%;
  left: 18%;
  animation: rotated 12s infinite linear;
}

.partner-section-one .shape-seven {
  top: 43%;
  left: 9%;
  animation: jumpTwo 3s infinite linear;
}

.partner-section-one .shape-eight {
  top: 10%;
  left: 19%;
  animation: rotatedTwo 12s infinite linear;
}


.title-style-nine h6 {
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #8a8a8a;
  padding-bottom: 25px;
}

.title-style-nine h2 {
  font-size: 52px;
  font-weight: 700;
  line-height: 1.34em;
}

.title-style-nine h2 span {
  display: inline-block;
  position: relative;
}

.title-style-nine h2 span img {
  position: absolute;
  max-height: 100%;
  z-index: -1;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.title-style-nine p {
  font-size: 20px;
  color: #6a6b72;
  padding-top: 28px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Theme Menu ^^^^^^^^^^^^^^^^^^^^^^^^^*/
.theme-main-menu {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  padding: 30px 70px;
  transition: all 0.4s ease-out;
}

.theme-main-menu.bg-none {
  background: transparent;
}

.theme-main-menu.sticky-menu.fixed {
  z-index: 9999;
  padding-top: 0;
  padding-bottom: 0;
  background: #fff;
  box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.1);
}

.theme-main-menu .logo a {
  display: block;
}

.theme-menu-one .logo img {
  max-width: 120px;
}

.theme-menu-one .right-button-group a {
  border-radius: 5px;
  font-weight: 500;
  font-size: 13px;
  line-height: 45px;
  text-transform: uppercase;
  text-align: center;
  color: var(--heading);
  transition: all 0.3s ease-in-out;
}

.theme-menu-one .right-button-group .signIn-action:hover {
  color: var(--blue-dark);
}

.theme-menu-one .right-button-group .signUp-action {
  width: 155px;
  color: #fff;
  background: var(--blue-dark);
  box-shadow: 0px 15px 40px rgba(161, 146, 250, 0.35);
  margin-left: 35px;
}

.theme-menu-one .right-button-group .signUp-action:hover {
  background: #ffba12;
  box-shadow: 0px 15px 40px rgba(255, 186, 18, 0.3);
}

.theme-menu-one .right-button-group {
  margin-left: 100px;
}


/* Home Brand Slider */
.partner-slider-two p {
  font-size: 20px;
  color: #000;
  padding-bottom: 45px;
}

.partner-slider-two p span {
  font-weight: 500;
}

.partner-slider-two .img-meta {
  width: 170px;
  /* Fixed width for the container */
  height: 100px;
  /* Fixed height for the container */
  display: flex;
  align-items: center;
  justify-content: center;
  /* Add padding for spacing around the images */
  box-sizing: border-box;
  /* Include padding in the element's total width and height */
}

.partner-slider-two .img-meta img {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  /* Maintain the aspect ratio */
  height: auto;
  /* Maintain the aspect ratio */
}

.partner-slider-two p.text-lg {
  font-size: 36px;
  line-height: 1.44em;
  font-weight: 500;
}

.partner-slider-two p.text-lg span {
  color: #ffb840;
}

/*-------------- Client Feedback Slider Six ----------------*/
.clientSliderSix {
  max-width: 1740px;
  margin: 30px auto 0;
}

.clientSliderSix .item {
  padding: 80px 25px;
}

.clientSliderSix .feedback-wrapper {
  border: 2px solid #e8edf0;
  border-radius: 10px;
  background: #fff;
  padding: 42px 44px 48px 57px;
  position: relative;
  z-index: 1;

  transition: all 0.3s ease-in-out;
}

.clientSliderSix .feedback-wrapper .ribbon {
  height: 8px;
  position: absolute;
  left: -1px;
  right: -1px;
  top: -1px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.clientSliderSix .slick-center .feedback-wrapper {
  border-color: #fff;
  box-shadow: 0px 35px 60px rgba(14, 29, 45, 0.04);
}

.clientSliderSix .feedback-wrapper:before {
  content: url(../../../public//images/icon/101.svg);
  position: absolute;
  bottom: 41px;
  right: 57px;
}

.clientSliderSix .feedback-wrapper p {
  font-size: 20px;
  line-height: 38px;
  color: #000;
  padding-bottom: 45px;
}

.clientSliderSix .feedback-wrapper .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
}

.clientSliderSix .feedback-wrapper .name {
  font-weight: 500;
  font-size: 18px;
}

.clientSliderSix .feedback-wrapper .name span {
  font-weight: normal;
  font-size: 16px;
  color: #8a8a8a;
}

.clientSliderSix .slick-dots {
  text-align: center;
}

.clientSliderSix .slick-dots li {
  display: inline-block;
}

.clientSliderSix.style-two .feedback-wrapper .ribbon {
  top: auto;
  bottom: -1px;
  height: 5px;
  transform: scale(0, 1);
  border-radius: 0 0 10px 10px;
  transition: all 0.3s ease-in-out;
}

.clientSliderSix.style-two .slick-center .feedback-wrapper .ribbon {
  transform: scale(1, 1);
}

/*----------------- Fancy Short Banner Eight ---------------*/
.fancy-short-banner-eight {
  background: #fcf7ea;
  padding: 90px 0 100px;
  position: relative;
  z-index: 1;
}

.fancy-short-banner-eight .download-btn {
  width: 280px;
  margin: 80px auto 0;
}

.fancy-short-banner-eight .download-btn button {
  width: 100%;
  line-height: 70px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  background: #000;
  text-align: center;
}

.fancy-short-banner-eight .download-btn button:after {
  content: url(../../../public//images/icon/102.svg);
  border: none;
  position: absolute;
  right: 22px;
  vertical-align: 0;
  transition: all 0.2s ease-in-out;
}

.fancy-short-banner-eight .download-btn.show button:after {
  transform: rotate(180deg);
}

.fancy-short-banner-eight .download-btn .dropdown-menu {
  width: 100%;
  background: #fff5d1;
  border-radius: 0px 0px 10px 10px;
  border: none;
  margin: -5px 0 0 0;
  padding: 5px 0 0px;
}

.fancy-short-banner-eight .download-btn .dropdown-menu a {
  font-weight: 500;
  font-size: 15px;
  color: #000;
  padding: 8px 15px;
  background: transparent;
}

.fancy-short-banner-eight .download-btn .dropdown-menu a:hover,
.fancy-short-banner-eight .download-btn .dropdown-menu a:focus {
  background: rgba(255, 255, 255, 0.5);
}

.fancy-short-banner-eight .download-btn .dropdown-menu a span {
  padding-left: 12px;
}

.fancy-short-banner-eight .shape-one {
  top: 18%;
  left: 10%;
  animation: jumpThree 4s infinite linear;
}

.fancy-short-banner-eight .shape-two {
  top: 54%;
  right: 9%;
  animation: jumpTwo 4s infinite linear;
}

/*----------------- Fancy Feature Twenty -------------------*/
.block-style-twenty .text-wrapper h6 {
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 35px;
  color: rgba(0, 0, 0, 0.63);
}

.block-style-twenty .text-wrapper .title {
  font-size: 60px;
  line-height: 1.2em;
  font-weight: 700;
}

.block-style-twenty .text-wrapper p {
  font-size: 20px;
  line-height: 1.8em;
  color: #4f4f4f;
  padding: 44px 0 44px;
}

.block-style-twenty .text-wrapper .video-button {
  font-weight: 500;
  font-size: 15px;
  text-transform: uppercase;
  color: #6b6df6;
  cursor: pointer;
}

.block-style-twenty .text-wrapper .video-button .fa {
  display: inline-block;
  font-size: 26px;
  margin-right: 15px;
}

.block-style-twenty .text-wrapper .video-button:hover span {
  text-decoration: underline;
}


/*----------------- Fancy Short Banner Nine ---------------*/
.fancy-short-banner-nine {
  background: #4b0e55;
  padding: 110px 0;
  position: relative;
  z-index: 1;
}

.fancy-short-banner-nine .title-style-seven h2,
.fancy-short-banner-nine .title-style-seven p {
  color: #fff;
}

.fancy-short-banner-nine .download-btn {
  width: 250px;
  margin: 30px auto 0;
}

.fancy-short-banner-nine .download-btn button {
  width: 100%;
  line-height: 65px;
  border-radius: 40px;
  font-weight: 500;
  font-size: 18px;
  color: #000;
  background: #fff;
  text-align: center;
}

.fancy-short-banner-nine .download-btn button:after {
  content: url(../../../public//images/icon/89.svg);
  border: none;
  position: absolute;
  right: 30px;
  vertical-align: 0;
  transition: all 0.2s ease-in-out;
}

.fancy-short-banner-nine .download-btn.show button:after {
  transform: rotate(180deg);
}

.fancy-short-banner-nine .download-btn .dropdown-menu {
  width: 100%;
  background: #fff;
  border-radius: 0px 0px 10px 10px;
  border: none;
  margin: -5px 0 0 0;
  padding: 5px 0 0px;
}

.fancy-short-banner-nine .download-btn .dropdown-menu a {
  font-weight: 500;
  font-size: 15px;
  color: #000;
  padding: 8px 15px;
  background: transparent;
}

.fancy-short-banner-nine .download-btn .dropdown-menu a span {
  padding-left: 12px;
}

.fancy-short-banner-nine .shape-one {
  bottom: 0;
  right: 0;
  width: 18%;
}

.fancy-short-banner-nine .shape-two {
  bottom: 0;
  left: 0;
  width: 17.4%;
}

/*=======================================================================
                              Product landing / Schedule Appointment           
=========================================================================*/
/*-------------------- Menu ---------------*/
.theme-menu-six .navbar-nav .nav-item .nav-link {
  font-size: 20px;
  margin: 0 25px;
  color: #fff;
}

.theme-menu-six .navbar-nav .nav-item .nav-link.active {
  text-decoration: underline;
}

.theme-menu-six .right-widget .signIn-action {
  font-size: 20px;
  line-height: 46px;
  color: #fff;
  margin: 0 30px 0 100px;
  transition: all 0.3s ease-in-out;

}

.theme-menu-six .right-widget .signIn-action:hover {
  text-decoration: underline;
}

.theme-menu-six .right-widget .signIn-action img {
  margin-right: 12px;
  margin-top: -3px;
}

.theme-menu-six .right-widget .signup-btn {
  width: 142px;
  line-height: 50px;
  font-size: 18px;

  background: #572ff6;
  border-radius: 30px;
  color: #fff;
  text-align: center;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.theme-menu-six.fixed .right-widget .signup-btn {
  background: #fff;
  color: #000;

}

.theme-menu-six .right-widget .signup-btn:hover {
  background: #fff;
  color: #000;
}

.theme-menu-six .right-widget .login-btn {
  width: 110px;
  line-height: 50px;
  font-size: 18px;

  background: #fff;
  border-radius: 30px;
  color: #000;
  text-align: center;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.theme-menu-six.fixed .right-widget .login-btn {

  background: #572ff6;
  color: #fff;
}

.theme-menu-six .right-widget .login-btn:hover {

  background: #572ff6;
  color: #fff;
}

.theme-menu-six.fixed .navbar-nav .nav-item .nav-link,
.theme-menu-six.fixed .right-widget .signIn-action {
  color: #000;
}


.theme-menu-six .right-widget .demo-button {
  width: 200px;
  font-size: 17px;
  line-height: 55px;
  border: 2px solid #fff;
  text-align: center;
  border-radius: 8px;
  font-weight: 500;
  color: #fff;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.theme-menu-six .right-widget .demo-button:hover {
  background: #fff;
  color: #000;
}

.theme-menu-six.fixed .right-widget .demo-button {
  background: #212121;
  color: #fff;
}

/*---------------------Theme Hero Banner/Nine ---------------*/
.hero-banner-nine {
  position: relative;
  z-index: 1;
  padding-top: 180px;
  padding-left: 20px;
  padding-right: 20px;
}

.hero-banner-nine .hero-heading {
  font-weight: 700;
  font-size: 72px;
  line-height: 1.25em;
}

.hero-banner-nine .hero-heading span {
  display: inline-block;
  position: relative;
}

.hero-banner-nine .hero-heading span img {
  position: absolute;
  max-height: 100%;
  z-index: -1;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.hero-banner-nine .hero-sub-heading {
  font-size: 24px;
  line-height: 1.5em;
  color: #2f2f2f;
  padding: 36px 0 58px 0;
}

.hero-banner-nine form {
  max-width: 550px;
  height: 70px;
  position: relative;
}


.hero-banner-nine form input {
  border: none;
  background: #f4f4f4;
  border-radius: 54px;
  width: calc(100% - 170px);
  height: 100%;
  font-size: 16px;
  padding: 0 50px;
}


.hero-banner-nine form button {
  position: absolute;
  right: 0;
  top: 0;
  background: #572ff6;
  width: auto;
  height: auto;
  padding: 18px;
  border-radius: 30px;
  text-align: center;
  color: #fff;
  font-size: 17px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.hero-banner-nine form button:hover {
  background: #ff4a8b;
}

.hero-banner-nine form ::-webkit-input-placeholder {
  /* Edge */
  color: #0b0b0b;
}

.hero-banner-nine form :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #0b0b0b;
}

.hero-banner-nine form ::placeholder {
  color: #0b0b0b;
}

.hero-banner-nine .term-text {
  font-size: 16px;
  color: #a5a5a5;
  margin-top: 22px;
}

.hero-banner-nine .term-text a {
  color: #572ff6;
}

.hero-banner-nine .bg-shape {
  top: 0;
  right: 0;
}

.hero-banner-nine .video-wrapper {
  max-width: 1050px;
  border-radius: 30px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  margin: 0 auto 20px;
}

.hero-banner-nine .video-wrapper video {
  display: block;
}

.hero-banner-nine .illustration-container {
  position: absolute;
  right: 0;
  top: 17%;
  z-index: 1;
  width: 44%;
  user-select: none;
}

.hero-banner-nine .illustration-container img {
  border-radius: 10px;
  /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.hero-banner-nine .illustration-container:before {
  content: "";
  position: absolute;
  top: 0;
  left: 70px;
  right: -30px;
  bottom: 50px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  z-index: -1;
}

.hero-banner-nine .illustration-video-container:after {
  content: "";
  position: absolute;
  top: -25px;
  left: 100px;
  right: -30px;
  bottom: 50px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  z-index: -2;
}

.hero-banner-nine .illustration-container video {
  display: block;
}

.hero-banner-nine .illustration-video-container {
  border-radius: 30px;
  overflow: hidden;
  position: absolute;
  right: 1%;
  top: 17%;
  content: "";
  z-index: 1;
  width: 50%;
  user-select: none;
}


.hero-banner-nine .illustration-video-container:before {
  content: "";
  position: absolute;
  top: 0;
  left: 70px;
  right: -30px;
  bottom: 50px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  z-index: -1;
}

.hero-banner-nine .illustration-video-container:after {
  content: "";
  position: absolute;
  top: -25px;
  left: 100px;
  right: -30px;
  bottom: 50px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  z-index: -2;
}

.hero-banner-nine .img-meta.justify-content-center {
  justify-content: start !important;
}

/*----------------- Fancy Feature Twenty Two -------------------*/
.block-style-twentyTwo {
  text-align: center;
  background: #fff;
  padding: 50px 35px;
  margin-top: 45px;
  transition: all 0.3s ease-in-out;
  border-radius: 6px;

  @media screen and (max-width: 991px) {
    box-shadow: 0px 30px 80px rgba(0, 57, 110, 0.05);
  }
}

.block-style-twentyTwo:hover {
  box-shadow: 0px 30px 80px rgba(0, 57, 110, 0.05);
}

.block-style-twentyTwo .icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0 auto;
}

.block-style-twentyTwo h4 {
  font-size: 24px;
  font-weight: 500;
  padding: 26px 0;
}

.block-style-twentyTwo .arrow-icon {
  margin-top: 30px;
  opacity: 0;
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 991px) {
    opacity: 1;
  }
}

.block-style-twentyTwo:hover .arrow-icon {
  opacity: 1;
}

/*----------------- Fancy Feature Twenty Three -------------------*/
.block-style-twentyThree {
  margin-bottom: 150px;
}

.block-style-twentyThree .text-wrapper h6 {
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #8a8a8a;
}

.block-style-twentyThree .text-wrapper .title {
  font-size: 42px;
  line-height: 1.3em;
  font-weight: 700;
  padding: 28px 0 24px;
}

.block-style-twentyThree .text-wrapper p {
  font-size: 20px;
  line-height: 1.85em;
}

.block-style-twentyThree .screen-container {
  position: relative;
  max-width: 480px;
  height: 415px;
}

.block-style-twentyThree .screen-container .oval-shape {
  width: 415px;
  height: 415px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.block-style-twentyThree .screen-container .oval-shape:nth-child(2) {
  left: auto;
  right: 0;
  z-index: -1;
}

.block-style-twentyThree .screen-container .oval-shape:nth-child(1) {
  mix-blend-mode: multiply;
  transform: rotate(30deg);
}

.block-style-twentyThree .screen-container .shape-one {
  z-index: 2;

  animation: jumpTwo 5s infinite linear;
}

.block-style-twentyThree .screen-container .shape-two {
  z-index: 2;

  animation: jumpThree 5s infinite linear;
}

.block-style-twentyThree .screen-container .shape-three {
  z-index: 2;

  animation: jumpTwo 5s infinite linear;
}

.block-style-twentyThree .screen-container img {
  border-radius: 15px; // Add rounded corners
  width: 95%; // Ensure images are always the same size
  height: auto;
}

/*----------------- Fancy Feature Twenty Four -------------------*/
.fancy-feature-twentyFour .bg-wrapper {
  background: #f5fbff;
  position: relative;
  z-index: 1;
  padding: 170px 0 130px;
}

.fancy-feature-twentyFour .click-scroll-button {
  position: absolute;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: #fff;
  z-index: 1;
  top: -25px;
  left: 50%;
  margin-left: -26px;
}

.fancy-feature-twentyFour .click-scroll-button img {
  width: 20px;
}

.fancy-feature-twentyFour .shape-one {
  top: -25px;
  right: 25%;
  animation: rotated 12s infinite linear;
}

.fancy-feature-twentyFour .shape-two {
  top: 47%;
  right: 8%;
  animation: rotated 12s infinite linear;
}

.fancy-feature-twentyFour .shape-three {
  bottom: -20px;
  right: 13%;
  animation: jumpTwo 3s infinite linear;
}

.fancy-feature-twentyFour .shape-four {
  bottom: 31%;
  left: 10%;
  animation: rotated 12s infinite linear;
}

.fancy-feature-twentyFour .shape-five {
  top: 13%;
  left: 10%;
  animation: jumpTwo 3s infinite linear;
}

.block-style-twentyFour {
  background: #fff;
  padding: 40px 43px 50px;
  box-shadow: 0 4px 0 #e9f3fa;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.block-style-twentyFour .icon {
  width: 70px;
  height: 70px;
  border-radius: 20px;
}

.block-style-twentyFour .text {
  width: calc(100% - 70px);
  padding-left: 32px;
}

.block-style-twentyFour .text h4 {
  font-size: 22px;
  font-weight: 500;
}

.block-style-twentyFour .text p {
  font-size: 16px;
  line-height: 28px;
  padding-top: 15px;
}

/*----------------- Partner Section One -------------------*/
.partner-section-one {
  position: relative;
  z-index: 1;
}

.partner-section-one .img-box a {
  display: block;
  height: 100%;
}

.partner-section-one .img-box img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
}

.partner-section-one .img-box {
  background: #fff;
  border-radius: 50%;
  box-shadow: 15px 30px 50px rgba(26, 34, 72, 0.08);
  width: 175px;
  height: 175px;
  margin: 0 auto 35px;
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 575px) {
    width: 150px;
    height: 150px;
  }
}

.partner-section-one.bg-transparent .img-box {
  box-shadow: 15px 30px 50px rgba(23, 32, 90, 0.06);
}

.partner-section-one .img-box:hover {
  transform: scale(1.1);
}

.partner-section-one .img-box.bx-b {
  width: 121px;
  height: 121px;
  margin-top: 45px;
}

.partner-section-one .img-box.bx-d {
  width: 151px;
  height: 151px;
  margin-top: 25px;
}

.partner-section-one .img-box.bx-f {
  width: 135px;
  height: 135px;
  margin-top: 20px;
}

.partner-section-one .img-box.bx-g {
  width: 197px;
  height: 197px;

  @media screen and (max-width: 575px) {
    width: 150px;
    height: 150px;
  }
}

.partner-section-one .img-box.bx-h {
  width: 138px;
  height: 138px;
  margin-top: 20px;
}

.partner-section-one .shape-one {
  top: 9%;
  right: 14%;
  animation: rotated 12s infinite linear;
}

.partner-section-one .shape-two {
  top: 44%;
  right: 9%;
  animation: rotatedTwo 12s infinite linear;
}

.partner-section-one .shape-three {
  bottom: 19%;
  right: 12%;
  animation: jumpTwo 3s infinite linear;
}

.partner-section-one .shape-four {
  bottom: 0;
  right: 36%;
  animation: rotated 12s infinite linear;
}

.partner-section-one .shape-five {
  bottom: 0;
  left: 36%;
  animation: rotatedTwo 12s infinite linear;
}

.partner-section-one .shape-six {
  bottom: 17%;
  left: 18%;
  animation: rotated 12s infinite linear;
}

.partner-section-one .shape-seven {
  top: 43%;
  left: 9%;
  animation: jumpTwo 3s infinite linear;
}

.partner-section-one .shape-eight {
  top: 10%;
  left: 19%;
  animation: rotatedTwo 12s infinite linear;
}

/*----------------- Fancy Short Banner Ten -------------------*/
.fancy-short-banner-ten {
  background: #f5fbff;
  padding: 110px 0 110px;
  position: relative;
  z-index: 1;
}

.fancy-short-banner-ten h2 {
  font-size: 60px;
  font-weight: 700;
}

.fancy-short-banner-ten p {
  font-size: 32px;
  line-height: 1.53em;
  color: #040404;
  padding: 30px 0 62px;
}

.fancy-short-banner-ten .download-btn {
  width: 300px;
  margin: 0 auto;
}

.fancy-short-banner-ten .download-btn button {
  width: 100%;
  line-height: 65px;
  border-radius: 40px;
  font-weight: 500;
  font-size: 18px;
  color: #000;
  background: transparent;
  border: 2px solid #000;
  text-align: center;
}

.fancy-short-banner-ten .download-btn button:after {
  // content: url(../../../public//images/icon/89.svg);
  border: none;
  position: absolute;
  right: 90px;
  vertical-align: 0;
  transition: all 0.2s ease-in-out;
}

.fancy-short-banner-ten .download-btn.show button:after {
  transform: rotate(180deg);
}

.fancy-short-banner-ten .download-btn .dropdown-menu {
  width: 100%;
  background: #fff;
  border-radius: 0px 0px 10px 10px;
  border: none;
  margin: 0;
  padding: 5px 0 0px;
}

.fancy-short-banner-ten .download-btn .dropdown-menu a {
  font-weight: 500;
  font-size: 15px;
  color: #000;
  padding: 8px 15px;
  background: transparent;
}

.fancy-short-banner-ten .download-btn .dropdown-menu a span {
  padding-left: 12px;
}

.fancy-short-banner-ten .info-text {
  text-align: center;
  font-size: 15px;
  color: #6b6b6b;
  padding-top: 15px;
}

.fancy-short-banner-ten .shape-one {
  bottom: 0;
  right: 0;
  width: 16.8%;
}

.fancy-short-banner-ten .shape-two {
  top: 0;
  left: 0;
  width: 16.25%;
}

/*-------------------- Footer Style Eight --------------------*/
.theme-footer-eight .footer-about-widget ul li a {
  font-size: 18px;
  color: #343434;
  margin-bottom: 5px;
  transition: all 0.2s ease-in-out;
}

.theme-footer-eight .footer-about-widget ul li a.phone {
  font-size: 20px;
}

.theme-footer-eight .footer-about-widget ul li a:hover {
  text-decoration: underline;
}

.theme-footer-eight .footer-title {
  font-size: 24px;
  font-weight: 500;
  color: #0e0e0e;
  position: relative;
  margin: 5px 0 21px;
}

.theme-footer-eight .footer-list ul li a {
  color: #343434;
  line-height: 40px;
  transition: all 0.2s ease-in-out;
}

.theme-footer-eight .footer-list ul li a:hover {
  text-decoration: underline;
}

.theme-footer-eight .top-footer .address-list p {
  font-size: 16px;
  line-height: 40px;
  padding-bottom: 15px;
}

.theme-footer-eight .top-footer .address-list ul.info li a {
  color: #1c1c1c;
  margin-bottom: 10px;
}

.theme-footer-eight .top-footer .address-list ul.info li a:hover {
  text-decoration: underline;
}

.theme-footer-eight .top-footer .address-list .social-icon a {
  font-size: 22px;
  margin-right: 20px;
  color: rgba(0, 0, 0, 0.55);
}

.theme-footer-eight .top-footer .address-list .social-icon a:hover {
  color: #000;
}

.theme-footer-eight .top-footer [class*="col-"] {
  margin-bottom: 35px;
}

.theme-footer-eight .bottom-footer p {
  font-size: 16px;
  color: #373737;
}

.theme-footer-eight .bottom-footer .social-icon a {
  font-size: 20px;
  margin-left: 15px;
  color: #323232;
}

/*----------------- Team Section Five --------------*/
.team-section-five {
  background: #f2fbfd;
  padding: 180px 0 150px;
}


//Partner section below banenr
.title-style-eight h2 {
  font-size: 68px;
  font-weight: 700;
  line-height: 1.26em;
}

.title-style-eight h2 span {
  display: inline-block;
  position: relative;
}

.title-style-eight h2 span img {
  position: absolute;
  max-height: 100%;
  z-index: -1;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.title-style-eight p {
  font-size: 22px;
  color: #000;
  padding-top: 40px;
}

.title-style-eight h6 {
  font-size: 16px;
  letter-spacing: 2px;
  color: rgba(0, 0, 0, 0.63);
  padding-bottom: 20px;
}


/*---------------- Modal Contact Form One ------------------*/
.modal-backdrop {
  z-index: 10000;
}

.modal {
  z-index: 10001;
}

.modal-contact-popup-one .modal-dialog {
  max-width: 1330px;
  padding: 0 15px;
}

.modal-contact-popup-one .main-body {
  background: #fff;
  border-radius: 30px;
  overflow: hidden;
  position: relative;
  width: 100%;
  border: none;
  -ms-flex-direction: row;
  flex-direction: row;
}

.modal-contact-popup-one .main-body .close {
  position: absolute;
  right: 30px;
  top: 27px;
  z-index: 5;
}

.modal-contact-popup-one .main-body .left-side {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  background: #fde3c5;
  text-align: center;
  padding: 50px 0 0;
}


.modal-contact-popup-one .main-body .left-side blockquote {
  font-weight: 500;
  font-size: 32px;
  line-height: 1.31em;
  color: #000;
  padding: 0 15px 15px;
}

.modal-contact-popup-one .main-body .left-side .bio {
  font-size: 20px;
  color: #000;
  display: block;
  padding-bottom: 15px;
}

.modal-contact-popup-one .main-body .left-side .illustration {
  width: 100%;
}

.modal-contact-popup-one .main-body .right-side {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding: 50px 5% 50px 5%;
  position: relative;
  z-index: 1;
}

.modal-contact-popup-one .main-body .right-side .form-title {
  font-size: 40px;
  font-weight: 500;
  color: #000;
  padding-bottom: 30px;
}

.dark-style.modal-contact-popup-one .main-body .right-side .form-title {
  color: #fff;
}

.modal-contact-popup-one .main-body .input-group-meta {
  position: relative;
}

.modal-contact-popup-one .main-body .input-group-meta label {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  padding-bottom: 8px;
  font-weight: normal;
  display: block;
}

.modal-contact-popup-one .main-body .input-group-meta input {
  width: 100%;
  height: 60px;
  border: 2px solid #000;
  border-radius: 6px;
  padding: 0 20px;
  color: #000;
  font-size: 17px;
  background: transparent;
}

.modal-contact-popup-one .main-body .input-group-meta textarea {
  max-width: 100%;
  width: 100%;
  height: 150px;
  border: 2px solid #000;
  border-radius: 6px;
  padding: 10px 20px;
  color: #000;
  font-size: 17px;
  background: transparent;
  display: block;
}

body .theme-btn-seven {
  font-weight: 500;
  text-align: center;
  line-height: 60px;
  color: #fff;
  border-radius: 6px;
  padding: 0 45px;
  background: #000;
  transition: all 0.3s ease-in-out;
}

body .theme-btn-seven:hover {
  background: #ff2759;
}


/*----------------- Terms and Condition ---------------*/
.terms_and_policy {
  padding: 120px 0;
  border-bottom: 1px solid #ececec;
}

.terms_and_policy .nav-tabs {
  border: none;
  background: #f2fbfd;
  padding: 30px 10px 30px 45px;
}

.terms_and_policy .nav-item {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  margin: 0;
}

.terms_and_policy .nav-item .nav-link {
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  line-height: 60px;
  padding: 0;
  background: transparent;
  border: none;
  color: #222;
  transition: all 0.2s ease-in-out;
}

.terms_and_policy .nav-item .nav-link.active {
  color: var(--blue-dark);
}

.terms_and_policy .tab-content {
  padding: 0 0 0 50px;
}

.terms_and_policy .tab-content h2 {
  font-size: 55px;
  padding-bottom: 10px;
}

.terms_and_policy .tab-content .update-date {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 20px;
}

.terms_and_policy .tab-content h3 {
  font-family: "gilroy-semibold";
  font-size: 28px;
  padding: 50px 0 25px;
}

.terms_and_policy .tab-content p {
  line-height: 34px;
  margin-bottom: 15px;
}

.terms_and_policy .tab-content p a {
  text-decoration: underline;
  color: #000;
}

.terms_and_policy .tab-content ul {
  list-style-type: disc;
  padding-left: 18px;
}

.terms_and_policy .tab-content ul li {
  color: #000;
}

.terms_and_policy .tab-content ul li a {
  text-decoration: underline;
  color: #000;
}

.terms_and_policy .tab-content ul li {
  padding-bottom: 10px;
}

/** LOGIN PAGE CSS **/
.user-data-page .illustration-wrapper {
  float: left;
  width: 48%;
  padding: 40px 0;
  min-height: 100vh;
  background: #f8fafe;
  text-align: center;
}

.user-data-page .illustration-wrapper h3 {
  font-size: 36px;
  line-height: 1.27em;
  padding: 40px 0;
}

.user-data-page .illustration-wrapper h3 a {
  text-decoration: underline;
}

.user-data-page .illustration-wrapper .illustration-holder {
  width: 75%;
  margin-top: 15%;
}

.user-data-page .illustration-wrapper .illustration-holder .illustration {
  width: 100%;
}

.user-data-page .illustration-wrapper .illustration-holder .shape-one {
  bottom: 2%;
  left: 2%;
  z-index: 1;
  width: 40%;
  animation: jumpTwo 5s infinite linear;
}

.user-data-page .illustration-wrapper .illustration-holder .shape-two {
  bottom: 2%;
  left: 59%;
  z-index: 1;
  width: 30%;
  animation: jumpThree 5s infinite linear;
}

.user-data-page .illustration-holder .shape-three {
  width: 45%;
  top: 9%;
  right: 51%;
  z-index: 1;
  animation: jump10p 5s infinite linear;
}

.user-data-page .form-wrapper {
  float: left;
  width: 47%;
  height: 100%;
  padding: 50px 4% 20px;
  position: relative;
}

.user-data-page .go-back-button {
  font-size: 15px;
}

.user-data-page .go-back-button:hover {
  color: #000;
  text-decoration: underline;
}

.user-data-page .form-wrapper h2 {
  font-family: "gilroy-bold";
  font-size: 58px;
  line-height: 1.17em;
}

.user-data-page .form-wrapper .header-info {
  font-size: 22px;
  color: var(--heading);
}

.user-data-page .form-wrapper .header-info a {
  color: var(--blue-dark);
  text-decoration: underline;
}

.user-data-page .form-wrapper .copyright-text {
  font-size: 16px;
}

.user-data-form .input-group-meta {
  height: 55px;
  position: relative;
}

.user-data-form .input-group-meta input {
  font-family: "Rubik", sans-serif;
  width: 100%;
  height: 100%;
  font-size: 18px;
  border: none;
  border-bottom: solid 2px #000;
  padding: 0 52px 0 0;
  color: var(--heading);
  background: transparent;
}

.user-data-form .input-group-meta input:focus {
  border-color: var(--blue-dark);
}

.user-data-form .input-group-meta label {
  font-size: 15px;
  font-weight: normal;
  color: #bfbfbf;
  position: absolute;
  left: 0;
  top: -21px;
}

.user-data-form .input-group-meta .placeholder_icon {
  position: absolute;
  line-height: 55px;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50px;
  text-align: center;
  z-index: 1;
  color: rgba(0, 0, 0, 0.45);
  font-size: 17px;
}

.user-data-form .input-group-meta .valid-sign img {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.user-data-form .input-group-meta input:valid+.valid-sign img {
  opacity: 1;
}

.user-data-form .input-group-meta .placeholder_icon img {
  position: relative;
  top: 50%;
  margin: 0 auto;
  transform: translateY(-50%);
}

.user-data-form .input-group-meta .placeholder_icon span {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: block;
  position: relative;
}

.user-data-form .input-group-meta .placeholder_icon span:before {
  content: "";
  width: 2px;
  height: 26px;
  background: #000;
  position: absolute;
  top: 15px;
  left: 24px;
  transform: rotate(45deg);
  z-index: 5;
  transition: all 0.2s ease-in-out;
}

.user-data-form .input-group-meta .placeholder_icon span.eye-slash:before {
  opacity: 0;
}

.user-data-form .agreement-checkbox label {
  position: relative;
  font-size: 15px;
  color: var(--heading);
  cursor: pointer;
  padding-left: 22px;
  line-height: 18px;
  transition: all 0.1s ease-in-out;
}

.user-data-form .agreement-checkbox label a {
  color: var(--p-color);
  text-decoration: underline;
}

.user-data-form .agreement-checkbox input[type="checkbox"] {
  display: none;
}

.user-data-form .agreement-checkbox label:before {
  content: "";
  width: 13px;
  height: 13px;
  line-height: 11px;
  border-radius: 2px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  font-size: 8px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 2px;
  transition: all 0.1s ease-in-out;
}

.user-data-form .agreement-checkbox input[type="checkbox"]:checked+label:before {
  content: "\f00c";
  background: #000;
  color: #fff;
  border-color: #000;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 9px;
}

.user-data-form .agreement-checkbox a {
  position: relative;
  font-size: 15px;
  color: rgba(30, 30, 30, 0.55);
}

.user-data-form .agreement-checkbox a:hover {
  text-decoration: underline;
  color: var(--p-color);
}

.user-data-form .theme-btn-one {
  width: 100%;
}

.user-data-page .full-height {
  min-height: 100vh;
  padding: 20px 0;
  flex-direction: column;
  text-align: center;
}

.full-height .user-data-form .button-solid-one {
  width: 200px;
  margin: 0 auto 30px;
}

body .theme-btn-one {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  line-height: 50px;
  background: #F38BA0;
  padding: 0 42px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  margin-top: 5%;
  margin-bottom: 3%;
}

body .theme-btn-one.btn-lg {
  padding: 0 50px;
  line-height: 55px;
  text-transform: uppercase;
  letter-spacing: 1.14px;
}

body .theme-btn-one:hover {
  background: #CC9696;
}

body .theme-google-btn {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  line-height: 50px;
  background: #8AC3C3;
  padding: 0 42px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  margin-top: 3%;
  margin-bottom: 5%;
}

body .theme-google-btn:hover {
  background: #629393;
}

.agreement-checkbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.agreement-checkbox-left {
  display: flex;
  align-items: center;
}

.agreement-checkbox-left label {
  margin-left: 5px;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  /* Prevent text selection */
}

.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  user-select: none;
}

.logo-text {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-left: 10px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  font-family: 'Rubik', sans-serif;
  user-select: none;
  /* Prevent text selection */
}


/*----------------- 404 ---------------*/
.error-page {
  min-height: 100vh;
}

.error-page .img-holder {
  width: 50%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.error-page .img-holder .illustration {
  min-height: 100vh;
  object-fit: cover;
  object-position: top center;
}

.error-page .img-holder .qus {
  top: 34%;
  left: 15%;
  width: 13%;
  z-index: 1;
  animation: fade-in 1s infinite linear;
}

.error-page .text-wrapper {
  width: 50%;
  padding: 50px 20px 50px 5%;
}

.error-page .text-wrapper h1 {
  font-size: 82px;
  line-height: 1.21em;
}

.error-page .text-wrapper p {
  font-size: 24px;
  line-height: 1.66em;
  font-weight: 300;
  color: #000;
  padding: 55px 15% 60px 0;
}

.error-page .text-wrapper .back-home {
  font-size: 17px;
  text-align: center;
  line-height: 55px;
  width: 200px;
  transition: all 0.3s ease-in-out;
  background: #000;
  padding: 0 20px;
  color: #fff;
}

.error-page .text-wrapper .back-home img {
  margin-left: 12px;
}

.error-page .text-wrapper .back-home:hover {
  background: var(--purple-blue);
  color: #fff;
}

.error-page .text-wrapper .logo {
  position: absolute;
  top: 50px;
  left: 5%;
}

.bg-2 {
  background: #f2fbfd;
}

.MuiTextField-root {
  margin-top: 4px; // Equivalent to theme.spacing(2)
  width: 100%;

  .MuiOutlinedInput-root {
    fieldset {
      border-color: black !important; // Changed border color to black
    }

    &:hover fieldset {
      border-color: black !important; // Changed border color to black
    }

    &.Mui-focused fieldset {
      border-color: black !important; // Changed border color to black
    }

    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    color: black !important; // Changed font color to black
  }

  .MuiInputLabel-root {
    color: black !important; // Changed font color to black
  }

  .MuiInputLabel-root.Mui-focused {
    color: black !important; // Changed font color to black
  }

  .MuiInputAdornment-root svg {
    color: black !important; // Changed icon color to black
  }
}

.MuiTypography-h5 {
  font-weight: bold;
  // Add other styles as needed
}

.custom-quill .ql-editor {
  font-size: 16px;
  /* Increase this value as needed */
}